import react, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import AppText from './AppText'
import * as SVGIcon from '../assets/svg'
const calendar: string = require('../assets/icons/calendar.svg').default
const calling: string = require('../assets/icons/calling.svg').default
const dash: string = require('../assets/icons/dash.svg').default
const memb: string = require('../assets/icons/memb.svg').default
const servv: string = require('../assets/icons/servv.svg').default
const star: string = require('../assets/icons/star.svg').default
const wallet: string = require('../assets/icons/wallet.svg').default
const work: string = require('../assets/icons/work.svg').default
const settings: string = require('../assets/icons/settingss.svg').default
const allusers: string = require('../assets/icons/allusers.svg').default
const logo: string = require('../assets/images/logomsmt.png')


interface sidebarProps {
   activeClass?: string
}
export default (props: sidebarProps) => {
   const Navigate = useNavigate()
   const [scrollDirection, setScrollDirection] = useState(null);
   const [newNav, setNewNav] = useState(false)
   const [newNav1, setNewNav1] = useState(true)

   useEffect(() => {
      let lastScrollY = window.pageYOffset;
      // function to run on scroll
      const updateScrollDirection = () => {
         const scrollY = window.pageYOffset;
         const direction: any = scrollY > lastScrollY ? "down" : "up";
         if (direction !== scrollDirection) {
            setScrollDirection(direction);
         }
         lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
         window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
   }, [scrollDirection]); // run when scroll direction changes
   const LogOut = () => {
      localStorage.removeItem('__mtoken')
      localStorage.removeItem('__mauth_id')
      Navigate("/")
   }
   return (
      <div className={`sidebar ${scrollDirection === 'down' ? 'fixedToTop' : ''}`}>
         {/* <div className={`sidebar`}> */}

         <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
            {/* <div>
               <img src={logo} />
            </div> */}
            <li onClick={() => Navigate('/dashboard')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'dashboard' ? "activeNav" : ""}`}><NavLink to="/dashboard"><span>
               <SVGIcon.DashboardOne />
            </span><AppText nclass='gohidden'>Dashboard</AppText></NavLink></li>

            <li onClick={() => Navigate('/members?type=individual')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'member' ? "activeNav" : ""}`}><NavLink to="/members?type=individual"><span>
               <SVGIcon.DashboardTwo />
            </span><AppText nclass='gohidden'>Members</AppText></NavLink></li>


            {/* <li onClick={() => setNewNav(!newNav)} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'member' ? "activeNav" : ""}`}>
               <NavLink to="#">
                  <span>
                     <SVGIcon.DashboardTwo />
                  </span>
                  <AppText nclass='gohidden'>Members</AppText> <span style={{ marginLeft: '15%' }}>{newNav ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}</span> </NavLink>

               {newNav ?
                  <ul className="toggle list-unstyled mleft19" id="homeSubmenu">
                     <li>
                        <NavLink to="/members?type=individual">Individual</NavLink>
                     </li>
                     <li>
                        <NavLink to="/members/business?type=business">Organization</NavLink>
                     </li>
                  </ul>
                  : ""}
            </li> */}

            <li onClick={() => setNewNav1(!newNav1)} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'provider' ? "activeNav" : ""}`}>
               <NavLink to="#">
                  <span>
                     <img src={allusers} />
                  </span>
                  <AppText nclass='gohidden'>Providers</AppText> <span style={{ marginLeft: '15%' }}>{newNav1 ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}</span> </NavLink>
               {newNav1 ?
                  <ul className="toggle list-unstyled mleft19" id="homeSubmenu">
                     <li>
                        <NavLink to="/providers?type=individual">Individual</NavLink>
                     </li>
                     <li>
                        <NavLink to="/providers?type=business">Organization</NavLink>
                     </li>
                  </ul>
                  : ""}
            </li>


            {/* <li onClick={() => Navigate('/providers')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'provider' ? "activeNav" : ""}`}><NavLink to="/providers"><span>
               <SVGIcon.DashboardThree />
            </span><AppText nclass='gohidden'>Service Providers</AppText></NavLink></li> */}

            <li onClick={() => Navigate('/sessions')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'sessions' ? "activeNav" : ""}`}><NavLink to="/sessions"><span>
               <SVGIcon.DashboardFour />
            </span><AppText nclass='gohidden'>Sessions</AppText></NavLink></li>
            <li onClick={() => Navigate('/services')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'services' ? "activeNav" : ""}`}><NavLink to="/services"><span>
               <SVGIcon.DashboardFive />
            </span><AppText nclass='gohidden'>Services</AppText></NavLink></li>

            <li onClick={() => Navigate('/transactions')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'transactions' ? "activeNav" : ""}`}><NavLink to="/transactions"><span>
               <SVGIcon.DashboardNine />
            </span><AppText nclass='gohidden'>Transactions</AppText></NavLink></li>

            {/* <li onClick={() => Navigate('/feedbacks')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'feedback' ? "activeNav" : ""}`}><NavLink to="/feedbacks"><span>
               <SVGIcon.DashboardSeven />
            </span><AppText nclass='gohidden'>Feedback</AppText></NavLink></li> */}
            <li onClick={() => Navigate('/management')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'management' ? "activeNav" : ""}`}><NavLink to="/management"><span>
               <SVGIcon.SettingIcon />
               {/* <img src={settings} /> */}
            </span><AppText nclass='gohidden'>User Mgt</AppText></NavLink></li>
            <li onClick={() => Navigate('/settings')} className={`marginBottom cursor-pointer cursor-hover ${props.activeClass === 'settings' ? "activeNav" : ""}`}><NavLink to="/settings"><span>
               <SVGIcon.SettingIcon />
               {/* <img src={settings} /> */}
            </span><AppText nclass='gohidden'>Settings</AppText></NavLink></li>
            <li onClick={() => LogOut()} className={`marginBottom cursor-pointer cursor-hover`}><NavLink to="#" onClick={() => LogOut()}><span>
               {/* <SVGIcon.DashboardSeven /> */}
               <i className="fa fa-sign-out" style={{ fontSize: "28px", color: "red" }}></i>
            </span><AppText nclass='gohidden'>Logout</AppText></NavLink></li>

         </ul>
      </div>
   )
}